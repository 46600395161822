import Vue from 'vue'
import Vuex from 'vuex'
import { setItem, getItem, removeItem } from '@/utils/storage.js'
import system from "./system"

Vue.use(Vuex)

// 用于保存登录成功的token名
export const TOKEN_NAME = 'TOUTIAO_USER'

const LOTTERY_NAME = 'LOTTERY'

const USER_PHONE = 'USERPHONE'

const USER_ID = 'userID'

export default new Vuex.Store({
  state: {
    // 这里将token保存到vuex 中，为了后面使用，这里为了代码的简介，我们可以将存储和获取封装成一หนึ่งสถานที่方法，使用的时候直接调用
    user: getItem(TOKEN_NAME),
    lottery: getItem(LOTTERY_NAME),
    userphone: getItem(USER_PHONE),
    userid: getItem(USER_ID),
    unreadMessage: ''
  },
  mutations: {
    setUser(state, data) {
      state.user = data
      setItem(TOKEN_NAME, data)
    },
    delteUer(state) {
      removeItem(TOKEN_NAME)
    },
    setLottery(state, data) {
      state.lottery = data
      setItem(LOTTERY_NAME, data)
    },
    getAnswer(state, playload) {
      // --------------重要
      const say = {
        // 自定义消息组件所需参数
        type: 1,
        css: 'left',
        txt: playload.content,
        date: '',
        headImg: playload.extra
      }
      state.answer.push(say)
      console.log(playload)
    },
    SET_UNREAD_MESSAGE(state, num) {
      if (!num) return (state.unreadMessage = '')
      state.unreadMessage = num
    }
  },
  actions: {
    verifyRes(content, { ret, msg, data }) {
      return new Promise((resolve, reject) => {
        if (ret == -1) {
          router.replace('/login')
          return reject({ ret, msg })
        }
        if (ret == 1) {
          return resolve(data)
        }
        Toast(msg)
        return reject({ ret, msg })
      })
    },
  },
  modules: {
    system
  }
})
