"use strict";
exports.__esModule = true;
var keepDigits = function (number, digital) {
    if (digital === void 0) { digital = 2; }
    var res = number.toString();
    var zeroNum = digital - res.length;
    for (var i = 0; i < zeroNum; i++) {
        res = "0" + res;
    }
    return res;
};
var format = function (time, format) {
    if (format === void 0) { format = "yyyy-MM-dd HH:mm:ss"; }
    if (typeof time === "number") {
        var len = time.toString().length;
        if (len === 10) {
            time *= 1000;
        }
    }
    var date = new Date(time);
    var formatTypes = {
        y: date.getFullYear(),
        M: date.getMonth() + 1,
        d: date.getDate(),
        H: date.getHours(),
        m: date.getMinutes(),
        s: date.getSeconds()
    };
    var res = format;
    for (var key in formatTypes) {
        if (Object.prototype.hasOwnProperty.call(formatTypes, key)) {
            var value = formatTypes[key];
            res = res.replace(new RegExp(key + "+"), keepDigits(value, 2));
        }
    }
    return res;
};
exports["default"] = format;
