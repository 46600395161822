// 存储token的函数
export function setItem (key, value) {
  if (value) {
    if (typeof value === 'object') {
      value = JSON.stringify(value)
    }
    window.localStorage.setItem(key, value)
  }
}

// 获取token的函数
export function getItem (key) {
  if (key) {
    try {
      return JSON.parse(window.localStorage.getItem(key))
    } catch (error) {
      return window.localStorage.getItem(key)
    }
  }
}

// 删除token的函数
export function removeItem (key) {
  window.localStorage.clear(key)
}

export const NotLoginLinkPage = () => {
  if (!getItem('TOUTIAO_USER')) {
    return false
  } else {
    return true
  }
}
