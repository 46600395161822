import verConfig from "./ver";

const formatMoney = (num, showUnit = true) => {
  if(verConfig.homeProfitFormat){
    num = moneyFormat(parseInt(num));
  }
  if(!showUnit){
    return num
  }
  const type = verConfig.moneyType;
  if (type == "₫") {
    return `${num}${type}`;
  }
  return `${type}${num}`;
}

const moneyFormat = (money) => {
  const arr = money.toString().split("").reverse();
  for (let i = 0, len = arr.length; i < Math.floor(len / 3); i++) {
    arr.splice(3 * (i + 1) + i, 0, ".")
  }
  if (arr[arr.length - 1] === ".") {
    arr.pop();
  }
  return arr.reverse().join("");
}
export default formatMoney