/*
 * @Author: your name
 * @Date: 2022-03-04 10:52:57
 * @LastEditTime: 2022-03-29 11:59:50
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vi\src\utils\request.js
 */
import axios from 'axios'
import store from '@/store/index.js'
import verConfig from "@/utils/ver"

export const baseUrl = verConfig.url;

export const isLinkGame = verConfig.isLinkGame || false; // true->wing; false->Shopee+1

// export const baseURL = isLinkGame
//   ? 'http://147.139.40.215:9091/api/'
//   // : 'http://147.139.40.32:9091/api/'
//   : 'https://xanh7.com/api/'
export const baseURL = verConfig.url;

const proxy =
  process.env.NODE_ENV === 'development' ? 'api' : `${baseUrl}api`
export const chatImg = verConfig.url;

const request = axios.create({
  // baseURL: 'https://wing85.com/api/'
  // baseURL: 'https://shrimpskins.shop/api/'
  baseURL: proxy
})
request.interceptors.request.use(
  config => {
    config.headers = {
      lang: 'vi',
      token: store.state.user ? store.state.user : null
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
request.interceptors.response.use(
  (response) => {
    // Do something with response data
    const { ret, msg } = response.data
    if (ret === -1) {
      // Toast(msg)
      // setTimeout(()=> {
      // router.replace("login")
      // },1000)
      localStorage.removeItem("TOUTIAO_USER")
    }
    return response
  }
  // (error) => {
  //   // Do something with response error
  //   if (error.response) {
  //     // The request was made and the server responded with a status code
  //     // that falls out of the range of 2xx
  //     const status = error.response.status
  //     // eslint-disable-next-line no-prototype-builtins
  //     if (ERROR_MESSAGE.hasOwnProperty(status)) {
  //       Toast(`${status} ${ERROR_MESSAGE[status]}`)
  //     } else {
  //       Toast(ERROR_MESSAGE.UNKNOWN_ERROR)
  //     }
  //   } else if (error.request) {
  //     // The request was made but no response was received
  //     // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  //     // http.ClientRequest in node.js
  //     Toast(ERROR_MESSAGE.NO_RESPONDING)
  //   } else {
  //     // Something happened in setting up the request that triggered an Error
  //   }
  //   return Promise.reject(error)
  // }
)

export const $get = (url, params) => request({
  url,
  method: 'get',
  params: (() => {
    if (!params) {
      params = new Object;
    }
    // params.lang = verConfig.lan || "en";
    return params
  })(),
  headers: {
    token: localStorage.getItem('token')
  }
})
export const $post = (url, data) => request({
  url,
  method: 'post',
  data,
  headers: {
    token: localStorage.getItem('token')
  }
})
export const $upload = ({url, data}) => {
  return request({
  url,
  method: 'post',
  data,
  headers: {
    token: localStorage.getItem('token'),
    "Content-Type": "multipart/form-data"
  }
})}
export default request
