import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 路由配置
const routes = [
  {
    path: '/',
    component: () => import('@/views/Layout/index.vue'),
    children: [
      {
        path: '', // 默认访问路径, 只允许一หนึ่งสถานที่
        redirect: '/home'
      },
      {
        path: '/home', // 默认访问路径, 只允许一หนึ่งสถานที่
        component: () => import('@/views/Home/index.vue')
      },
      {
        path: '/lottery',
        component: () => import('@/views/Lottery/index.vue')
      },
      {
        path: '/Draw',
        // name: 'draw',
        component: () => import('@/views/win')
      },
      {
        path: '/ChatList',
        name: 'ChatList',
        component: ()=> import('@/views/chatroom'),
        meta: {
          login: true
        }
      },
      // {
      //   path: '/ChatList',
      //   name: 'ChatList',
      //   component: () => import('@/views/chat/chat_list.vue')
      // },
      {
        path: '/brand',
        component: () => import('@/views/Brand/index.vue')
      },
      {
        path: '/discover',
        component: () => import('@/views/Discover/index.vue')
      },
      {
        path: '/discounts',
        component: () => import('@/views/Discounts/index.vue')
      },
      {
        path: '/my',
        component: () => import('@/views/My/index.vue')
      },
      {
        path: '/Third',
        component: () => import('@/views/Third/index.vue')
      }
      // {
      //     path: '/login',
      //     component: () => import('@/views/My/index.vue')
      // },
      ,
      {
        path: '/TreasureChest',
        component: () => import('@/views/blindBox')
      }
    ]
  },

  /**
   * TO DO
   * 把login移出来
   */

  {
    path: '/login',
    component: () => import('@/views/My/component/NotLogin')
  },
  {
    path: '/registe',
    component: () => import('@/views/My/component/registe.vue')
  },

  {
    name: 'details',
    path: 'details', // 默认访问路径, 只允许一หนึ่งสถานที่
    component: () => import('@/views/Home/components/Details.vue')
  },
  {
    path: '/discount/detail',
    name: 'discountDetail',
    component: () => import('@/views/Discounts/components/DiscountDetail')
  },
  {
    path: '/recharge',
    component: () => import('@/views/components/User_Pay.vue')
  },
  {
    path: '/account',
    component: () => import('@/views/My/component/AccountData.vue')
  },
  {
    path: '/withdraw',
    component: () => import('@/views/components/User_Extract.vue')
  },
  {
    path: '/single',
    name: 'UserSingle',
    component: () => import('@/views/components/User_Single.vue')
  },
  {
    name: 'payBank',
    path: '/user/pay_bank',
    component: () => import('@/views/components/User_Pay_Bank.vue')
  },
  {
    path: '/bank',
    component: () => import('@/views/components/User_Bank.vue')
  },
  {
    path: '/user/pay_password',
    component: () => import('@/views/components/User_Pass_Pay.vue')
  },
  {
    path: '/user/pay/record',
    component: () => import('@/views/components/User_pay_record.vue')
  },
  {
    path: '/user/withdraw/record',
    component: () => import('@/views/components/User_withdraw_record.vue')
  },
  {
    path: '/user/order',
    name: 'userOrder',
    component: () => import('@/views/components/User_Order.vue')
  },
  {
    path: '/user/order2',
    name: 'userOrder2',
    component: () => import('@/views/components/User_Order2.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/views/My/component/logout.vue')
  },
  {
    path: '/personal',
    name: 'Personal',
    component: () => import('@/views/My/component/personal.vue')
  },
  {
    path: '/editpasword',
    name: 'EditPssword',
    component: () => import('@/views/My/component/enditpassword.vue')
  },
  {
    path: '/game-info',
    name: 'GameInfo',
    component: () => import('@/views/My/component/game-info.vue')
  },
  {
    path: '/game-money-info',
    name: 'GameMoneyInfo',
    component: () => import('@/views/My/component/game-money-info.vue')
  },
  {
    path: '/lottery/detail',
    name: 'lotteryDetail',
    component: () => import('@/views/components/LotteryDetail.vue')
  },
  {
    path: '/singleDetails',
    name: 'singleDetaile',
    component: () => import('@/views/components/User_Single_Details.vue')
  },
  {
    path: '/balance',
    name: 'Balance',
    component: () => import('@/views/components/User_Balance.vue')
  },
  {
    path: '/yubao',
    name: 'YuBao',
    component: () => import('@/views/components/User_YuE_Bao.vue')
  },
  {
    path: '/download',
    component: () => import('@/views/components/Download')
  },
  {
    path: '/yuebaoRule',
    name: 'Rule',
    component: () => import('@/views/components/YueBao_Rule.vue')
  },
  {
    path: '/Rollout',
    name: 'Rollout',
    component: () => import('@/views/components/YueBao_Rollout.vue')
  },
  {
    path: '/YuebaoRecord',
    name: 'YuebaoRecord',
    component: () => import('@/views/components/YueBao_Record.vue')
  },
  {
    path: '/Usermessage',
    name: 'Usermessages',
    component: () => import('@/views/components/User_message.vue')
  },
  {
    path: '/discoverReview',
    name: 'DiscoverReview',
    component: () => import('@/views/Discover/components/Discover_Review.vue')
  },
  {
    path: '/discoverPublish',
    name: 'DiscoverPublish',
    component: () => import('@/views/Discover/components/Discover_Publish.vue')
  },
  {
    path: '/commentItem',
    name: 'CommentItem',
    component: () => import('@/views/Discover/components/Comment_item.vue')
  },
  {
    path: '/picture',
    component: () => import('@/views/Discover/components/picture.vue')
  },
  {
    path: '/gameShows',
    name: 'GameShows',
    component: () => import('@/views/components/Game_shows.vue')
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: () => import('@/views/My/component/agreement')
  },
  {
    path: '/forget-pwd',
    name: 'ForgetPwd',
    component: () => import('@/views/My/component/forget-pwd')
  },
  {
    path: '/share',
    name: 'Share',
    component: () => import('@/views/components/Share.vue')
  },
  {
    path: '/user-agent',
    name: 'UserAgent',
    component: () => import('@/views/components/UserAgent.vue')
  },
  {
    path: '/user-agent-apply-for',
    name: 'UserAgentApplyFor',
    component: () => import('@/views/components/UserAgentApplyFor.vue')
  },
  {
    path: '/share-money-list',
    name: 'ShareMoneyList',
    component: () => import('@/views/components/ShareMoneyList.vue')
  },
  {
    path: '/user/message',
    name: 'userOrderMeassge',
    component: () => import('@/views/components/User_Order_message.vue')
  },
  // {
  //   path: '/ChatList',
  //   name: 'ChatList',
  //   component: ()=> import('@/views/chatroom/chatlist'),
  //   meta: {
  //     login: true
  //   }
  // },
  {
    path: '/ChatRoom',
    name: 'ChatRoom',
    component: ()=> import('@/views/chatroom/chatroom'),
    meta: {
      login: true
    }
  },
  // {
  //   path: '/Draw',
  //   name: 'draw',
  //   component: () => import('@/views/win')
  // }
  {
    path: '/newFriends',
    name: 'newFriends',
    component: ()=> import('@/views/chatroom/newFriends'),
    meta: {
      login: true
    }
  },
  {
    path: '/groups',
    name: 'groups',
    component: ()=> import('@/views/chatroom/groups'),
    meta: {
      login: true
    }
  },
  {
    path: '/addFriends',
    name: 'addFriends',
    component: ()=> import('@/views/chatroom/addFriends'),
    meta: {
      login: true
    }
  },
  {
    path: '/friendList',
    name: 'friendList',
    component: ()=> import('@/views/chatroom/friendList'),
    meta: {
      login: true
    }
  },
  {
    path: '/WinningRecord',
    name: 'WinningRecord',
    component: ()=> import('@/views/blindBox/records'),
    meta: {
      login: true
    }
  },
]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫
// router.beforeEach((to, from, next) => {
//     const url = ['/', '/brand', '/discover', '/discounts', '/my', '/registe', '/discoverReview', '/login', '/lottery']
//     // to 表示要跳转的路径
//     // from 表示从哪หนึ่งสถานที่路径跳转过来的
//     // next() 表示放行，让它跳转到想去的路径   next('/) 表示强制跳转到某หนึ่งสถานที่指定的页面
//     // 1、如果请求的路径是login ，则直接让它跳转
//     // if (to.path === '/login') return next()
//     // 2、如果请求的路径不是login, 则先 获取token 的值

//     const tokenStr = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).token : ''
//     // 3、判断tokenStr 是否存在
//     // a、如果不存在为空，
//     // b、如果存在，则直接跳转
//     if (tokenStr || from.path === "/my") {
//         next()
//     } else {
//         url.includes(to.path) ? next() : next('/my')
//     }
// })

export default router
