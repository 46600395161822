export const ver = 'A6231'
const verArr = {
  A6231: {
    moneyType: '₫',
    homeProfitFormat: true,
    url: 'https://wing68.cc/',
    title: 'Wing',
    // mineLogo: require('@/assets/images/mine_icon.png'),
    // mineBG: require('@/assets/images/vi_mine_bg.png'),
    // hasChat: true,
    needMoneyNot: true,
    // chatUrl: 'https://dd5556.com',
    isLinkGame: true,
    hasDiscover: true,
    hasNotLoginOut: true
    // isThird: true
  },
  test: {
    moneyType: '₫',
    homeProfitFormat: true,
    url: 'http://149.129.175.229:6201/',
    title: 'Tmarketing',
    mineLogo: require('@/assets/images/mine_icon.png'),
    mineBG: require('@/assets/images/vi_mine_bg.png'),
    hasChat: true,
    needMoneyNot: true,
    chatUrl: 'http://8.218.138.123:8001'
    // isThird: true
  }
}
export default verArr[ver]
