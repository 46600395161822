<template>
  <div>
    <div :style="{ '--size': `calc(${size}rem / 16 / 2)` }" class="sevice" @click.prevent.stop="toServer"
      @touchstart.prevent.stop="start" @touchmove.prevent.stop="move" @touchend.prevent.stop="end" ref="btnService">
      <slot></slot>
    </div>
    <div class="height" ref="h"></div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 48
    }
  },
  data() {
    return {
      url: "",
      width: 0,
      height: 0,
      moveFlag: false,
      startX: 0,
      startY: 0,
    }
  },
  mounted() {
    this.width = document.body.clientWidth;
    this.height = this.$refs.h.clientHeight;
  },
  methods: {
    getServiceUrl() {
      this.$api.getNotice(({ service_url }) => {
        this.url = service_url;
      });
    },
    toServer() {
      window.location.href = this.url;
    },
    start(e) {
      const touch = e.touches;
      const { clientX, clientY } = touch[0];
      this.startX = clientX;
      this.startY = clientY;
    },
    move(e) {
      this.moveFlag = true;
      const btn = this.$refs.btnService;
      const touch = e.touches;
      const { clientX, clientY } = touch[0];
      if (clientY <= this.size / 2) {
        btn.style.top = 0 + "px";
      } else if (clientY >= this.height - this.size / 2) {
        btn.style.top = this.height - this.size + "px";
      } else {
        btn.style.top = clientY - this.size / 2 + "px";
      }
      if (clientX <= this.size / 2) {
        btn.style.right = this.width - this.size + "px";
      } else if (clientX >= this.width - this.size / 2) {
        btn.style.right = 0 + "px";
      } else {
        btn.style.right = this.width - clientX - this.size / 2 + "px";
      }
    },
    end(e) {
      if (!this.moveFlag) {
        // this.toServer();
        this.$emit("click")
      }
      this.moveFlag = false;
      const btn = this.$refs.btnService;
      const touch = e.changedTouches;
      const { clientX, clientY } = touch[0];
      if (clientX >= this.width / 2) {
        btn.style.right = 0;
      } else {
        // btn.style.right = this.width - this.size + "px";
        btn.style.right = this.width - this.size + "px";
      }
      if (
        Math.abs(clientX - this.startX) < this.size &&
        Math.abs(clientY - this.startY) < this.size
      ) {
        // this.toServer();
        this.$emit("click")
        return;
      }
      if (clientY <= this.size / 2) {
        btn.style.top = 0 + "px";
      } else if (clientY >= this.height - this.size / 2) {
        btn.style.top = this.height - this.size + "px";
      } else {
        btn.style.top = clientY - this.size / 2 + "px";
      }
    },
  }
}
</script>

<style lang="less" scoped>
.sevice {
  position: fixed;
  right: 0;
  bottom: 30vh;
  z-index: 9999;
  width: var(--size);
  height: var(--size);

  // img {
  //   width: var(--size);
  //   height: var(--size);
  // }
}
.height {
  // display: none;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -50;
}
</style>