import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
// 导入移动端vue 核心组件库
import Vant from 'vant'

// 导入vant 组件库的样式
import 'vant/lib/index.css'
// 导入字体样式
import './assets/fonts/fonts.css'

// 加载动态设置rem 基准值
import 'amfe-flexible'

// 加入自定义 dayjs 日期
import './utils/day.js'
// 导入全局样式文件
import './styles/index.less'

import VueClipboard from 'vue-clipboard2'
import { isLinkGame } from '@/utils/request'

// axios.baseURL = process.env.VUE_APP_URL;
// console.log('VUE_APP_URL', process.env.VUE_APP_URL)
import verConfig, { ver } from './utils/ver'
import formatMoney from './utils/formatMoney'
import formatImage from "./utils/imgUrlFormat"

Vue.prototype.ver = ver;
Vue.prototype.verConfig = verConfig;
Vue.prototype.$money = formatMoney;
Vue.prototype.$img = formatImage;

document.title = verConfig.title;
// 动态更改网站图标
const icoLink = document.createElement('link');
icoLink.type = 'image/png';
icoLink.rel = 'icon';
const icoVer = ver;
icoLink.href = require(`@/assets/ico/${icoVer}.png`);
document.head.appendChild(icoLink);
// process.env.VUE_APP_URL = verConfig.url;

import format from './utils/format'

Vue.prototype.$time = format;
Vue.prototype.$format = format;

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(VueClipboard)

Vue.config.productionTip = false
Vue.prototype.$isLinkGame = isLinkGame


// 挂载vant 组件库
Vue.use(Vant)
axios.defaults.baseURL = '/api'

Vue.use(Viewer)
Viewer.setDefaults({
  Options: { inline: true, button: true, navbar: true, title: true, toolbar: true, tooltip: true, movable: true, zoomable: true, rotatable: true, scalable: true, transition: true, fullscreen: true, keyboard: true, url: 'data-source' }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
